import React from 'react';
import Layout from 'layouts/Layout';
import Container from 'layouts/Container';
import SEO from 'components/seo';
import styled from 'styled-components';
import { Colors, Queries } from 'styles';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

const PackageContent = styled.div`
  margin: 2rem 0;
  background-color: ${Colors.bg_lite};
  padding: 1.5rem 2rem;

  & > .title {
    font-size: 2rem;
    color: ${Colors.gdmc_blue};
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .tierContainer {
    border: 1px solid ${Colors.grey_lite};
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 1rem;

    img {
      padding: 1rem;
      width: 100%;
    }
  }

  .tierTitle {
    font-size: 1.25rem;

    color: white;
    background-color: ${Colors.gdmc_blue};
    padding: 0.75rem 1rem;
    margin: 0;

    @media ${Queries.md} {
      font-size: 1.5rem;
    }
  }

  .tierContent {
    padding: 1rem;

    & > p:last-child,
    & > ul:last-child,
    & > ol:last-child {
      margin-bottom: 0;
    }

    img {
      width: 100%;
    }
  }
`;

const Package = ({ location, data }) => {
  const { i18n } = useTranslation();
  const packageData = data.prismicPackage.data;
  const tiers = packageData.package_tier;
  return (
    <Layout location={location}>
      <SEO lang={i18n.language} title={packageData.title.text} />
      <Container>
        <PackageContent>
          <h1 className="title">{packageData.title.text}</h1>
          {tiers.map((tier, idx) => (
            <div className="tierContainer" key={idx}>
              {tier.tier_name.text.length > 0 && (
                <h2 className="tierTitle">{tier.tier_name.text}</h2>
              )}
              {tier.tier_image?.localFile && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={tier.tier_image.localFile.url}
                >
                  <img
                    src={tier.tier_image.localFile.url}
                    alt={tier.tier_image.alt}
                  />
                </a>
              )}
              <div
                className="tierContent"
                dangerouslySetInnerHTML={{ __html: tier.tier_description.html }}
              />
            </div>
          ))}
        </PackageContent>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query PackageQuery($uid: String, $lang: String) {
    prismicPackage(uid: { eq: $uid }, lang: { eq: $lang }) {
      data {
        title {
          text
        }
        package_tier {
          tier_description {
            html
          }
          tier_name {
            text
          }
          tier_image {
            localFile {
              url
            }
            alt
          }
        }
      }
    }
  }
`;

export default Package;
